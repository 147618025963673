/* This managed the styling of all the Pages */

/* General Reset */
h1,
h2,
h3,
p,
ul,
li {
  margin: 0;
  padding: 0;
  font-family: "Arial", sans-serif;
  color: #333;
}

/* Main Container */
.pageDiv {
  padding: 20px;
  line-height: 1.6;
  padding-bottom: 70px;
}

.pageDiv img {
  display: block;
}

/* Top Section */
.topDiv {
  max-width: 1200px;
  text-align: center;
  margin: 20px auto;
  padding: 20px;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

/* Page Title */
.topDiv h1 {
  text-transform: uppercase;
  font-size: 2.5rem;
  color: #16a085;
}

.topDiv h2 {
  font-size: 2rem;
  padding-bottom: 10px;
  color: #1abc9c;
}

.topDiv p {
  font-size: 1.2rem;
  color: #555;
  margin-top: 20px;
  font-style: italic;
}

/* Main Content */
.pageMainDiv {
  padding: 20px;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

/* Table Styles */
.pageMainDiv table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
}

.pageMainDiv table th,
table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.pageMainDiv table th {
  background: #1abc9c;
  color: white;
  font-weight: bold;
}

.pageMainDiv table tr:hover {
  background: #f1f1f1;
}

.splitDiv {
  flex: 1;
  padding: 20px;
}

/* Typography and Lists */
.pageMainDiv h2 {
  font-size: 1.8rem;
  color: #1abc9c;
  margin-bottom: 15px;
}

.pageMainDiv h3 {
  font-size: 1.5rem;
  color: #2c3e50;
  margin-bottom: 10px;
}

.pageMainDiv p {
  font-size: 1rem;
  color: #555;
  margin-bottom: 15px;
  line-height: 1.8;
}

.pageMainDiv ul {
  list-style-type: disc;
  padding-left: 20px;
}

.pageMainDiv ul li {
  font-size: 1rem;
  color: #555;
  margin-bottom: 10px;
}

.pageMainDiv li::marker {
  color: #27ae60;
  font-weight: bold;
}

/*Loading Spinner for the page*/
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes changeColor {
  0% {
    color: #1abc9c;
  }
  100% {
    color: blue;
  }
}

/* Loading Spinner for the page */
#LoadingLogo {
  width: 50%;
  height: auto;
  margin: 0 auto;
  display: block;
  animation: spin 2s ease-in-out infinite, changeColor 5s linear 10s forwards;
  color: #1abc9c;
}

/* Responsive Design */
@media (max-width: 768px) {
  .pageDiv h1 {
    font-size: 2rem;
  }

  .pageDiv h2 {
    font-size: 1.5rem;
  }

  .pageDiv h3 {
    font-size: 1.2rem;
  }

  .splitDiv {
    width: 90%;
    padding: 10px;
  }

  .pageMainDiv table th,
  table td {
    font-size: 0.9rem;
    padding: 10px;
  }
}
